<template>
  <div class="dragonInfo">
    <Nav />

    <div class="py-24">
      <section class="text-gray-600 body-font">
        <div class="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600">
          </div>
          <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl font-medium text-gray-900">Dragon Test</h1>
            <p class="mb-8 leading-relaxed">#228</p>
            <h2 class="title-font sm:text-2xl text-1xl font-medium text-gray-900">Bio</h2>
            <p class="mb-8 leading-relaxed">Hey cutie. I'm Ruwa Greykey. I'm stunning and cynical, and sometimes even superficial. Let's spend the whole day listening to Paula Abdul together. Hip hip hooray!</p>
            
            <div class="flex w-full md:justify-start justify-center items-end">
              <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
                <label for="hero-field" class="leading-7 text-sm text-gray-600 font-bold">Buy</label>
                <input type="text" id="hero-field" name="hero-field" placeholder="Price" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-pink-200 focus:bg-transparent focus:border-pink-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
              </div>
              <button class="inline-flex text-white font-semibold bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">Bid</button>
            </div>
            <p class="text-sm mt-2 text-gray-500 mb-8 w-full">Bid fee is about 0.52 💎</p>

            <div class="flex w-full md:justify-start justify-center items-end">
              <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
                <label for="hero-field" class="leading-7 text-sm text-gray-600 font-bold">Setbid</label>
                <input type="text" id="hero-field" name="hero-field" placeholder="Price" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-pink-200 focus:bg-transparent focus:border-pink-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
              </div>
              <button class="inline-flex text-white font-semibold bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">Bid</button>
            </div>
            <p class="text-sm mt-2 text-gray-500 mb-8 w-full">Bid fee is about 0.52 💎</p>

            <div class="flex w-full md:justify-start justify-center items-end">
              <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
                <label for="hero-field" class="leading-7 text-sm text-gray-600 font-bold">Offer for sale</label>
                <input type="text" id="hero-field" name="hero-field" placeholder="Price" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-pink-200 focus:bg-transparent focus:border-pink-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
              </div>
              <button class="inline-flex text-white font-semibold bg-pink-500 border-0 py-2 px-6 focus:outline-none hover:bg-pink-600 rounded text-lg">Offer</button>
            </div>
            <p class="text-sm mt-2 text-gray-500 mb-8 w-full">Offer fee is about 0.52 💎</p>
          </div>
        </div>
      </section>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import Nav from "../components/Nav";
  import Footer from "../components/Footer";

  export default {
    components: {Nav, Footer}
  }
</script>